<template>
  <div>
    <div class="order">
      <div>{{ offset + idx + 1 }}</div>
    </div>
    <div class="col">
      <div class="columns">
        <div class="column is-1">
          {{ ConvertToDateTime.ConvertUnixToDatetime(data.TransactionDate) }}
        </div>
        <div class="column is-2">
          {{
            data.ShopName != undefined && data.ShopName != null
              ? data.ShopName
              : ""
          }}
        </div>
        <div class="column is2">{{ data.CifStaff }}</div>
        <div class="column is-1 paid-money">
          {{ formatCurrency(data.RealMoney) }}
        </div>
        <div class="column is-1 paid-money">
          {{ formatCurrency(data.ReferMoney) }}
        </div>
          <div class="column is-1 paid-money">
          {{ formatCurrency(data.RealMoney-data.ReferMoney) }}
        </div>
        <div class="column is-2">{{ data.ReceiptCode }}</div>
        <div class="column is-2">{{ data.Reason }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import ConvertToDateTime from "@/helpers/ConvertDatetime.vue";
export default {
  props: ["data", "idx", "offset"],
  data(){
    return{
        ConvertToDateTime:ConvertToDateTime
    }
  }
}
</script>