<template>
  <section>
    <div class="main-content">
      <div class="pawn-detail-content">
        <div class="header-bar-asset-config">
          <img class="back-btn" src="@/assets/img/prev.svg" alt />
          <div class="title-asset-config">
            Tạm ứng chưa hoàn ứng hết
          </div>
        </div>
        <div class="filter is-gapless columns mar-10">
          <div class="column is-12">
            <div class="columns is-gapless dropdown-search-custom">
              <b-field class="column is-3">
                <b-datepicker
                  class=""
                  :locale="locale"
                  placeholder="Từ ngày đến ngày"
                  icon-right="calendar-today"
                  :years-range="[-100, 100]"
                  trap-focus
                  v-model="selectDate"
                  @input="filterData()"
                  range
                >
                </b-datepicker>
              </b-field>
              <b-field class="column is-3 custom-w-22">
                <v-select
                  ref="model"
                  placeholder="PGD Nhận"
                  class="style-chooser"
                  label="Name"
                  v-model="filter.shopCode"
                  :options="listShop"
                  :reduce="(option) => option.Code"
                  @input="GetListFundTransfer()"
                  :disabled="!isHo"
                >
                  <div slot="no-options">Không có kết quả phù hợp</div>
                </v-select>
              </b-field>
                  <b-field class="column is-3">
                <v-select
                  ref="model"
                  placeholder="Thủ quỹ"
                  class="style-chooser"
                  label="FullName"
                  v-model="filter.user"
                  :options="lstUser"
                   @input="filterData()"
                  :reduce="(option) => option.UserName"
                >
                  <div slot="no-options">Không có kết quả phù hợp</div>
                </v-select>
              </b-field>
            </div>
          </div>
        </div>
        <div>
          <PagingCustom :filter="filter" :total="total" />
          <div class="header-table">
            <div class="order">#</div>
            <div class="col">
              <div class="columns">
                <div class="column is-1 ">Ngày GD</div>
                <div class="column is-2">PGD</div>
                <div class="column is-2">CIF/Nhân viên</div>
                <div class="column is-1 ">Số tiền</div>
                <div class="column is-1 ">Số tiền đã hoàn ứng</div>
                <div class="column is-1 ">Số dư công nợ</div>
                <div class="column is-2 ">Số phiếu</div>
                <div class="column is-2 ">Lý do</div>
                
              </div>
            </div>
          </div>
          <div class="main-table tbl-bank-transfer-list">
            <div
              class="item-table"
              v-for="(item, idx) in transactions"
              :key="item.Id"
            >
              <RevenueExpenditurePendingItem :idx="idx" :data="item" :offset="filter.offset" />
            </div>
          </div>
          <PagingItem :filter="filter" :total="total" />
        </div>
      </div>
    </div>
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
  </section>
</template>
<script>
import FundInventorySecService from "@/modules/fundinventorysec/services/FundInventorySecService.vue";
import FundTransferService from "@/modules/fundtransfers/services/FundServices.vue";
import ConvertToDateTime from "@/helpers/ConvertDatetime.vue";
import Enum from "@/helpers/Enum.vue";
import RevenueExpenditurePendingItem from "@/modules/fundinventorysec/components/RevenueExpenditurePendingItem.vue";
import UserService from "@/modules/user/services/UserService.vue";
import PagingCustom from "@/modules/fundtransfers/components/PagingCustom.vue";
export default {
    components:{RevenueExpenditurePendingItem,PagingCustom},
  data() {
    return {
        locale:"vi-ve",
      selectDate: [],
      filter: {
        offset: 0,
        limit: 10,
        fromDate: null,
        toDate: null,
        shopCode: null,
        user:null
      },
      total: 0,
      listShop: [],
      isLoading: false,
      isHo: false,
      transactions:[],
       lstUser:[], 
       filUser: {
        ShortName: null,
      }
    };
  },
  mounted() {
    let _self = this;
    _self.GetListPGD();
    let shopSelected = JSON.parse(localStorage.getItem("currentShop"));
      _self.filUser.ShortName = shopSelected.ShortNameV2;
      _self.filter.shopCode=shopSelected.Code;
       _self.GetEmpoyee();
       _self.loadGrid();
  },
  methods: {
      async GetEmpoyee() {
      let _self = this;
      //let shopSelected = JSON.parse(localStorage.getItem("currentShop"));
      let result = await UserService.Get_ListEmployeePGD(
        _self.filUser.ShortName
      );
      _self.lstUser = result;
    },
    GetListFundTransfer() {
      let _self=this;
      _self.filter.offset = 0;
      if(_self.filter.shopCode!=null && _self.filter.shopCode!="")
       _self.filUser.ShortName = _self.listShop.filter(
        (x) => x.Code ==  _self.filter.shopCode
      )[0].Code;
      _self.filter.user=null;
       _self.GetEmpoyee();
      _self.loadGrid();
    },
    filterData(){
      let _self=this;
      _self.filter.offset = 0;
      _self.loadGrid();
    },
    async loadGrid() {
      let _self = this;
      if (_self.selectDate[0] != null){
        _self.filter.fromDate = ConvertToDateTime.ConvertDatetimeToUnix(
          _self.selectDate[0]
        );
        _self.filter.toDate = ConvertToDateTime.ConvertDatetimeToUnix(
          _self.selectDate[1]
        );
      }
      if(_self.user==null)_self.user='';
      var result = await FundInventorySecService.Get_ListAdvancePending(
        _self.filter
      );
     _self.transactions=result.Data;
    _self.total=result.Total;
    },
    async GetListPGD() {
      let _self = this;
      _self.isLoading = true;
      _self.isHo = false;
      var result = await FundTransferService.Get_ListPGDNew();
      _self.listShop = result.Data;
      let shopSelected = JSON.parse(localStorage.getItem("currentShop"));
      let vir = _self.listShop.filter((x) => x.Code == shopSelected.Code);
      _self.filter.shopCode = vir[0].Code;
      if (vir[0].BusinessOfficeCode == Enum.OFFICE.HO) _self.isHo = true;
      _self.isLoading = false;
    },
    async ExportExcel() {
      let _self = this;
      let filterModel = {
        shopCode:_self.filter.shopCode,
        fromDate:_self.filter.fromDate,
        toDate:_self.filter.toDate,
        user: _self.filter.user
      };
      let result = await FundInventorySecService.Post_ExportExcelAdvancePending(filterModel);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(new Blob([result]));
      link.setAttribute("download", "danh_sach_tam_ung_cho_xac_nhan.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    pageChanged() {
      let _self = this;
      _self.loadGrid();
      _self.$router.push({
        name: "FundInventoryPending",
        query: {
          Offset: _self.filter.offset,
          Limit: _self.filter.limit,
          Search: _self.filter.search,
        },
      });
    }
  },
};
</script>